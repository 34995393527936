<script setup lang="ts">
import { HomeBanners } from "~/types";
const prepareImgUrl = useImage();

const props = defineProps<{
	banner: HomeBanners[number];
}>();

const badgeLabel = computed(() => props.banner?.badgeLabel && parseJSON(props.banner?.badgeLabel));
</script>

<template>
	<div class="banner-container">
		<ABanner
			:img-attrs="{
				alt: 'banner',
				src: '/nuxt-img/banners/banner-footer-desk.webp',
				width: 1182,
				height: 758,
				loading: 'lazy'
			}"
			:sources="[
				{
					srcset: prepareImgUrl('nuxt-img/banners/banner-footer-desk.webp', {
						width: 706,
						height: 483,
						loading: 'lazy'
					}),
					width: 706,
					height: 483,
					media: '(max-width: 767px)'
				}
			]"
			background="url('/nuxt-img/banners/banner-footer-bg.jpg')"
			class="banner"
		>
			<NuxtImg src="/nuxt-img/banners/coins.webp" class="coins" alt="coins" loading="lazy" />
			<div class="content">
				<div class="header">
					<AText :modifiers="['uppercase', 'bold', 'nowrap']" variant="tamale" class="title">{{
						banner?.smallTitle
					}}</AText>
					<AText class="text-caracas" variant="taipei" :modifiers="['uppercase', 'bold', 'nowrap']">{{
						banner?.subTitle
					}}</AText>
				</div>
				<div class="auth">
					<OHomeForm />
				</div>
				<div v-if="badgeLabel" class="badge">
					<picture>
						<source
							:srcset="
								prepareImgUrl('/nuxt-img/banners/badge.png', {
									format: 'webp',
									width: 175,
									height: 174,
									quality: 100,
									loading: 'lazy'
								})
							"
							format="webp"
							width="175"
							height="174"
							media="(max-width: 767px)"
							loading="lazy"
						/>
						<NuxtImg
							src="nuxt-img/banners/badge.png"
							format="webp"
							width="272"
							height="271"
							alt="badge"
							loading="lazy"
						/>
					</picture>
					<div class="badge-content">
						<AText variant="taiz" as="div" :modifiers="['bold']" class="percent">{{ badgeLabel.percent }}</AText>
						<AText variant="taipei" as="div" :modifiers="['uppercase']" class="text">{{ badgeLabel.text }}</AText>
					</div>
				</div>
			</div>
		</ABanner>
	</div>
</template>

<style scoped lang="scss">
section {
	flex-shrink: 0;
	background-repeat: no-repeat;
	background-size: cover;
	--a-banner-default-height: auto;
	--a-banner-default-pictute-height: 378px;
	--a-banner-default-picture-right-position: -16px;

	@include media-breakpoint-down(md) {
		--a-banner-default-picture-right-position: 50%;
		--a-banner-default-height: auto;
		--a-banner-default-pictute-height: 248px;
		--a-banner-default-overflow: visible;
		--a-banner-default-picture-top-position: -35px;
	}
}

.banner-container {
	padding-top: gutter(3);
	padding-bottom: gutter(5);
}

.banner {
	&:deep(> div) {
		position: relative;
		@include media-breakpoint-down(md) {
			position: relative;
		}
	}

	@include media-breakpoint-down(md) {
		background: url(public/nuxt-img/banners/footer-banner-bg-mob.jpg);
		background-repeat: no-repeat;
		background-size: cover;
	}

	&:deep {
		picture {
			@include media-breakpoint-down(md) {
				top: -50px;
			}
		}

		img {
			@include media-breakpoint-up(lg) {
				scale: 1.08;
			}
		}
	}
}

.content {
	width: 100%;
	padding: gutter(3) gutter(7);
	display: flex;
	flex-direction: column;
	gap: gutter(1.5);
	@include media-breakpoint-down(md) {
		padding: gutter(2);
		padding-top: 200px;
		align-items: center;
	}
}

.header {
	display: flex;
	flex-direction: column;
	gap: gutter(0.5);

	@include media-breakpoint-down(md) {
		align-items: center;
	}
}

.title {
	line-height: 40px;
}

.banner-container {
	@include media-breakpoint-down(lg) {
		margin: 0 gutter(2);
	}
	@include media-breakpoint-down(md) {
		margin-top: gutter(5);
	}
}

.auth {
	position: relative;
	width: 624px;
	z-index: 1;

	@include media-breakpoint-down(md) {
		width: 100%;
	}
}

.badge {
	display: block;
	position: absolute;
	right: -30px;
	bottom: -40px;
	width: 239px;
	height: 239px;
	animation: rotate-shake 2s ease-in-out infinite;

	@include media-breakpoint-down(md) {
		width: 180px;
		height: 180px;
		bottom: auto;
		top: 56px;
		right: -48px;
	}

	> picture {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	img {
		height: 100%;
		width: 100%;

		@include media-breakpoint-up(md) {
			scale: 1;
		}
	}

	.percent {
		line-height: 1;
		text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

		@include media-breakpoint-down(md) {
			font-size: 28px;
		}
	}

	.text {
		line-height: 1;
	}
}

.badge-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) rotate(20deg);
}

.coins {
	position: absolute;
	z-index: 1;
	animation: levitate-animation 4s ease-in-out infinite;
	pointer-events: none;
	width: 100%;
	left: 50%;
	max-width: 362px;

	@include media-breakpoint-up(md) {
		left: 73%;
		max-width: 500px;
	}

	@include media-breakpoint-up(xl) {
		left: 80%;
	}
}

@keyframes levitate-animation {
	0% {
		transform: rotate(0) scale(1) translateX(-50%);
	}

	50% {
		transform: rotate(2deg) scale(1.06) translateX(-50%);
	}

	100% {
		transform: rotate(0) scale(1) translateX(-50%);
	}
}

@keyframes rotate-shake {
	0% {
		transform: rotate(-3deg);
	}

	50% {
		transform: rotate(3deg);
	}

	100% {
		transform: rotate(-3deg);
	}
}
</style>
